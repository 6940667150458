<template>
  <div>
    <h1 class="title is-1">{{ $route.meta.title }}</h1>
    <div class="columns is-multiline is-clearfix">
      <div v-for="item in items"
           :key="item.id"
           class="column is-12 is-3-desktop is-2-fullhd">
        <div class="list-item has-background-success py-6 px-4 has-text-centered has-text-white title is-2">
          <router-link :to="`${item.id}`"
                       append
                       class="has-text-white">{{ item.name }}
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="items.length === 0"
         class="box columns column is-half has-background-warning title is-size-5">
      Nincs megjeleníthető találat
    </div>
  </div>
</template>

<script>

export default {
  name: 'Lists',
  components: {},
  computed: {
    items () {
      const selectedInspection = this.$store.state.inspection.selected
      const key = this.$route.meta.key
      return selectedInspection && key && Array.isArray(selectedInspection[key]) ? selectedInspection[key] : []
    },
  },
}
</script>

<style scoped>

.column {
  min-width: 320px;
}

.list-item {
  width: 300px;
}
</style>
